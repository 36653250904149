
.techno-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://assets.codepen.io/13471/squares.svg') repeat;
    opacity: 0.1;
    animation: moveBackground 2s linear infinite;
    z-index: 0;
  }
  
  @keyframes moveBackground {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-100%, -100%);
    }
  }
  
  body {
    margin: 0;
    font-family: 'Roboto Mono', monospace;
    background: linear-gradient(135deg, #6a11ce, #770909);
    color: #fff;
  }
  